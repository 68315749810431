<template>
  <div class="page-layout__inner">

    <div :class="'page-layout__filter' + (filterSettings.visible ? '' : ' w-auto')">
      <section :class="'section  section--shadow-0  section--p-0' + (filterSettings.visible ? ' h-100' : '')">
        <button @click="filterSettings.visible = true" :class="'section__filter-btn' + (filterSettings.visible ? ' section__filter-btn--hidden' : '')">
          <svg class="section__filter-icon">
            <use xlink:href="#icon__filter"></use>
          </svg>
        </button>

        <div :class="'section__filter' + (filterSettings.visible ? '' : ' section__filter--hidden')">
          <div class="section__header">
            <h2 class="section__title">
              <svg class="section__filter-icon">
                <use xlink:href="#icon__filter"></use>
              </svg>
              {{ getTranslation('filter') }}
            </h2>

            <div class="section__controls">
              <div class="section__controls-group">
                <a @click="filterSettings.visible = false" class="btn btn-link " href="javascript:void(0)">{{ getTranslation('hide') }}</a>
                <a class="btn  btn-link" href="javascript:void(0)">{{ getTranslation('reset') }}</a>
              </div>
            </div>
          </div>

          <div class="section__body">
            <div class="form-group">
              <form method="get" @submit.prevent="fetchItems(1)">
                <label class="form__label form__label--block">{{ getTranslation('search') }}</label>
                <div class="field-advanced">
                  <div class="field-advanced__inner">
                    <div class="field-advanced__field-wrap">
                      <input v-model="filterForm.query" class="field  field--full-width  field--bg-grey  js-borderOnFocus" type="text" :placeholder="getTranslation('search')">
                    </div>

                    <div class="field-advanced__right">
                      <button class="btn btn-primary">
                        <svg class="btn__icon">
                          <use xlink:href="#icon__search"/>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="form-group">
              <label class="form__label form__label--block">{{ getTranslation('type') }}</label>
              <VSelect
                  :options="formOptions.types"
                  label="title"
                  :reduce="option=>option.id"
                  v-model="filterForm.type_id"
                  :placeholder="getTranslation('selectFromList')"
              />
            </div>
          </div>

        </div>
      </section>
    </div>

    <div class="page-layout__content">
      <p class="section__descr">{{ getTranslation('resultFoundQuantity', pagination.total) }}</p>
      <section class="section mt-2 section--shadow-0">
        <div class="section__header">
          <div class="section__controls">

          </div>
        </div>

        <div class="section__body">

          <div class="table-responsive table-3-wrapper">

            <table class="table table-borderless">
              <thead class="table-3__head">
              <tr class="table-3__row">
                <th class="table-3__cell  table__hcell" style="width: 0.2%">#</th>
                <th class="table-3__cell  table__hcell" style="width: 10%">{{ getTranslation('code') }}</th>
                <th class="table-3__cell  table__hcell" style="width: 20%">{{ getTranslation('title') }}</th>
                <th class="table-3__cell  table__hcell" style="width: 10%">{{ getTranslation('type') }}</th>
                <th class="table-3__cell  table__hcell" style="width: 5%">{{ getTranslation('status') }}</th>
              </tr>
              </thead>
              <tbody>

              <tr v-for="item in items" v-bind:key="item.id" class="table-3__row">
                <td class="table-3__cell">{{ item.id }}</td>
                <td class="table-3__cell"> {{ item.code }}</td>
                <td class="table-3__cell"> {{ item.title }}</td>
                <td class="table-3__cell"> {{ item.type.title }}</td>
                <td class="table-3__cell"> {{ item.status_text }}</td>
              </tr>

              </tbody>
            </table>

          </div>


          <CPagination
              v-if="pagination.last_page > 1"
              class="mt-5"
              :active-page.sync="pagination.current_page"
              :pages="pagination.last_page"
              :limit="10"
          />

        </div>

      </section>
    </div>
    <modal
        :title="getTranslation('add')"
        :show.sync="showModal"
        :centered="false"
        @modalCancel="closeModal"
        cancel-button-label="close"
        ok-button-label="save"
        @clickOK="save"
    >
      <CForm>
        <div class="form-group">
          <label class="form__label  form__label--block">{{ getTranslation('title') }}</label>
          <input type="text" class="field" v-model="form.title" :class="{'is-invalid':$v.form.title.$error}"
                 :placeholder="getTranslation('title')">
        </div>
        <div class="form-group">
          <label class="form__label  form__label--block">{{ getTranslation('code') }}</label>
          <input type="text" class="field" v-model="form.code"
                 :placeholder="getTranslation('code')">
          <p class="section__descr">{{ getTranslation('Leave it blank to auto-generate') }}</p>
        </div>
        <div class="form-group">
          <label class="form__label form__label--block">{{ getTranslation('type') }} <span
              class="required">*</span></label>
          <VSelect
              :options="formOptions.types"
              :class="{'is-invalid':$v.form.type_id.$error}"
              label="title"
              :reduce="option=>option.id"
              v-model="form.type_id"
              :placeholder="getTranslation('selectFromList')"
          />
        </div>
        <div class="form-group mt-3">
          <label class="form__label form__label--block">{{ getTranslation('coupon_description') }}</label>
          <ckeditor :editor="editorOptions.editor" v-model.trim="form.description" :config="editorOptions.editorConfig"></ckeditor>
        </div>
        <div class="form-group">
          <label class="form-check-label form__label cursor-pointer">
            {{ getTranslation('status') }}
          </label>
          <div class="form-check mt-3 cursor-pointer">
            <input class="form-check-input cursor-pointer" type="checkbox" v-model="form.status" id="defaultCheck1">
            <label class="form-check-label form__label cursor-pointer" for="defaultCheck1">
              {{ getTranslation('STATUS_ACTIVE') }}
            </label>
          </div>
        </div>
      </CForm>
    </modal>
  </div>
</template>

<script>

import required from "vuelidate/lib/validators/required";
import ckeditorMixin from "@/components/mixins/Ckeditor";

export default {
  name: "Index",
  mixins: [ ckeditorMixin ],
  data() {
    return {
      filterSettings: {
        visible: true
      },
      items: [],
      pagination: {
        total: 1,
        last_page: 1,
        current_page: 1
      },
      filterForm: {},
      formOptions: {
        types: []
      },
      form: {
        title: '',
        code: '',
        description: '',
        status: 1,
        generate: 0,
      },
      showModal: false,
    }
  },
  created() {
    this.fetchItems();
    this.fetchFormOptions();

    let actions = [];

    if ( this.hasPermission('coupon.create') )
      actions.push(
          {
            label: this.getTranslation('add'),
            action: this.openModal,
            icon: {
              icon: '#icon__shape-add'
            }
          }
      );

    this.setHeadings({
      title: this.getTranslation('coupons'),
      breadcrumbs: [
        {
          to: { name: 'coupon.getList' },
          text: this.getTranslation('coupons')
        }
      ],
      actions: actions
    });

  },
  watch: {
    'filterForm.type_id'() {
      this.fetchItems(1);
    }
  },
  methods: {
    fetchFormOptions() {
      this.axios.get(this.$urls.coupon.formOptions.url)
          .then(response => {
            this.formOptions = response.data.data;
          }).catch(() => {
      });
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.resetForm();
    },
    save() {
      this.$v.form.$touch();

      if ( this.$v.form.$invalid )
        return this.snotifyError('wrongFormMsg');

      if ( !this.form.code )
        this.form.generate = 1;

      this.axios.post(this.$urls.coupon.getList.url, this.form)
          .then(response => {
            this.snotifySuccess('saveSuccessMsg');
            this.fetchItems(1);
            this.closeModal();
          }).catch(() => {
      });

    },
    resetForm() {
      this.$v.$reset();
      this.form = {
        title: '',
        code: '',
        description: '',
        status: 1,
      };
    },
    fetchItems(page = null) {

      this.filterForm.page = page;

      let params = { ...this.cleanObject(this.filterForm) };

      return this.axios.get(this.$urls.coupon.getList.url, { params: params }).then(response => {
        this.items = response.data.data.items;
        this.pagination = response.data.data.pagination;
      }).catch(() => {
      });
    }
  },
  validations: {
    form: {
      title: { required },
      type_id: { required },
    }
  }
}
</script>

<style scoped>

</style>