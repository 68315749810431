import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const ckeditorMixin = {
    data() {
        return {
            editorOptions: {
                editor: ClassicEditor,
                model: null,
                editorData: '',
                editorConfig: {
                    language: 'ru',
                    toolbar: [
                        'heading', '|',
                        'bulletedList',
                        'numberedList',
                        'highlight', 'alignment', 'bold', 'italic', '|',
                        'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor',
                         'blockQuote', '|',
                        'underline', 'strikethrough',
                        'subscript', 'superscript',
                    ],
                },
                tagName: 'textarea',
            },
        }
    }
}

export default ckeditorMixin;
